<!--
 * @Author: zhouyajuan
 * @Date: 2020-09-22 16:33:32
 * @LastEditTime: 2021-02-04 08:31:41
 * @LastEditors: yangliao
 * @Description: In User Settings Edit
 * @FilePath: /netHallOfficialAccounts/src/views/Bind/householderInfo.vue
-->
<template>
  <div class="householderInfo-page" id="householderInfo">
    <div class="bind-header">
      <p>请填写户主信息</p>
      <span>您在水司已预留手机、证件信息才可以找到哦~</span>
    </div>

    <div class="bind-content" id="bind">
      <van-form>
        <van-cell-group>
          <van-field
            v-model="dataInfo.userName"
            label="姓名"
            placeholder="用水户主姓名"
            clearable
            @input="getCheckDisabled"
            :rules="[{ validator: validatorName, message: '' }]"
          />
          <van-field
            v-model="dataInfo.phoneNum"
            label="手机号"
            placeholder="水司预留手机号"
            clearable
            type="number"
            maxlength="11"
            @input="getCheckDisabled"
            :rules="[{ validator: validatorPhone, message: '' }]"
          />
          <van-field
            label="验证码"
            placeholder="填写验证码"
            center
            clearable
            type="number"
            maxlength="6"
            v-model="dataInfo.verificationCode"
            @input="getCheckDisabled"
          >
            <template #button>
              <van-button
                size="mini"
                type="default"
                class="sms-button"
                @click="getCode"
                :disabled="isDisabled"
              >
                {{ buttonContent }}
              </van-button>
            </template>
          </van-field>
          <!-- 身份证下拉： class="idCard-span"  @click="showPopupIDCard" readonly -->
          <van-field
            v-model="dataInfo.idNum"
            label="身份证"
            placeholder="用水户主身份证号"
            clearable
            @input="getCheckDisabled"
            :rules="[{ validator: validatorIDCard, message: '' }]"
          />
        </van-cell-group>
        <span class="bind-userinfo" @click="goToBind">通过用水户号绑定</span>
        <div class="submit-button">
          <!-- :disabled="isDisabledSubmit" -->
          <van-button block type="info" @click="onSubmit" :disabled="isDisabledSubmit">
            下一步
          </van-button>
        </div>
      </van-form>
    </div>

    <!-- 弹出框 -->
    <van-popup
      v-model="show"
      round
      closeable
      close-icon-position="top-left"
      position="bottom"
      :style="{ height: '50%' }"
      get-container="#householderInfo"
    >
      <div class="title">
        <p>请选择证件类型</p>
        <span>需为户主在水司预留的证件</span>
      </div>
      <div class="content">
        <van-cell-group>
          <van-radio-group v-model="radio">
            <van-radio
              v-for="(item, index) in IDCardList"
              :key="index"
              :name="item.value"
              @click="changeRadio(item.name)"
            >
              <van-cell :title="item.name">
                <template #icon="props">
                  <!-- <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" /> -->
                  <van-icon name="success" v-if="props.checked" />
                </template>
              </van-cell>
            </van-radio>
          </van-radio-group>
        </van-cell-group>
      </div>
    </van-popup>
  </div>
</template>

<script>
import invertedTriangle from '../../assets/icon/invertedTriangle@2x.png';
import { isMobile, identityId, checkName } from '@/utils/validator';
import { bindUserByIdNum, getCode } from '../../api/home';
import { Dialog, Toast } from 'vant';
import store from '@/store';
import { mapState } from 'vuex';
export default {
  name: 'householderInfo',
  computed: {
    ...mapState('user', {
      homeInfo: (state) => state.homeInfo
    })
  },
  data() {
    return {
      invertedTriangle,
      dataInfo: {
        idNum: '',
        idType: '', // 01 身份证
        phoneNum: '',
        userName: '',
        verificationCode: ''
      },
      isDisabled: false, // 验证码按钮
      buttonContent: '获取验证码',
      wait: 60,
      show: false,
      radio: '1',
      IDCardList: [
        { name: '身份证', value: '1' },
        { name: '往来大陆通行证', value: '2' },
        { name: '台胞证', value: '3' },
        { name: '外国人永久居留身份证', value: '4' }
      ],
      isDisabledSubmit: true // 提交按钮
    };
  },
  mounted() {},
  methods: {
    // 校验
    validatorName(val) {
      return checkName(val);
    },
    validatorIDCard(val) {
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      return identityId(val);
    },
    validatorPhone(val) {
      return isMobile(val);
    },
    // 显示身份证类型弹框
    showPopupIDCard() {
      this.show = true;
    },
    // 选择证件类型
    changeRadio(value) {
      this.dataInfo.idNum = value;
      this.show = false;
    },
    // 跳转到-通过用水户号绑定
    goToBind() {
      this.$router.push({ path: '/Bind' });
    },
    // 获取验证码
    async getCode() {
      if (this.validatorPhone(this.dataInfo.phoneNum)) {
        this.getSendMessage();
        let arg = {
          phone: this.dataInfo.phoneNum
        };
        const { status, resultData } = await getCode(arg);
        if (status === 'complete') {
          Toast({ icon: 'passed', message: '发送成功！' });
        } else {
          Toast({ icon: 'close', message: '发送失败！' });
        }
      } else {
        Toast('请填写正确的手机号');
      }
    },
    // 验证码倒计时
    getSendMessage() {
      if (this.wait === 0) {
        this.isDisabled = false;
        this.buttonContent = '重新获取验证码';
        this.wait = 60;
      } else {
        this.isDisabled = true;
        this.buttonContent = this.wait + 's后重新获取';
        this.wait--;
        setTimeout(() => {
          this.getSendMessage();
        }, 1000);
      }
    },
    // 提交
    async onSubmit(values) {
      if (Object.values(this.dataInfo).length > 0) {
        // 再次校验
        if (
          this.validatorName(this.dataInfo.userName) &&
          this.validatorPhone(this.dataInfo.phoneNum) &&
          this.validatorIDCard(this.dataInfo.idNum)
        ) {
          this.dataInfo.idType = '01';
          let arg = this.dataInfo;
          const { status, resultData } = await bindUserByIdNum(arg);
          if (status === 'complete') {
            // 过滤没有绑定的数据，如果数据为空，则弹出一个弹框提示用户，不为空，跳到下一页，客户选择户号绑定
            let unBindData = [];
            resultData &&
              resultData.infos &&
              resultData.infos.map((item, index) => {
                if (!item.isChoose) {
                  unBindData.push(item);
                }
              });

            if (unBindData && unBindData.length <= 0) {
              Dialog.alert({
                message: '非常抱歉，未查询到该客户的任何未绑定的用水账户，如有疑问请联系客服核实'
              }).then(() => {
                // on close
              });
            } else {
              this.$router.push({
                path: '/Bind/selectBindAccount',
                name: 'SelectBindAccount',
                query: {
                  dataInfo: { ...this.dataInfo }
                }
              });
            }
          } else {
            Toast('请填写用户信息');
          }
        } else {
          Toast('请填写正确的用户信息');
        }
      }
    },
    getCheckDisabled() {
      const { idNum, phoneNum, userName, verificationCode } = this.dataInfo;
      if (
        idNum.length > 0 &&
        phoneNum.length > 0 &&
        userName.length > 0 &&
        verificationCode.length > 0
      ) {
        this.isDisabledSubmit = false;
      } else {
        this.isDisabledSubmit = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.householderInfo-page {
  overflow-x: hidden;
  background-color: #ffffff;
  .bind-header {
    margin-top: 33px;
    padding-left: 16px;
    text-align: left;
    p {
      font-family: PingFangSC-Medium;
      font-size: 24px;
      color: #1c2734;
      line-height: 26px;
      margin: 0px;
      font-weight: 700;
    }
    span {
      display: block;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      line-height: 26px;
      line-height: 26px;
      margin-bottom: 36px;
      padding-top: 2px;
    }
  }
  .bind-content {
    display: inline-block;
    width: 100%;
    /deep/.van-cell__title {
      span {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #333333;
      }
    }
    /deep/.van-field__control {
      display: inline-block;
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #5e6268;
    }
    /deep/.van-field__right-icon {
      font-size: 16px;
    }
    .sms-button {
      color: #999999;
      border: 1px solid #999999;
      border-radius: 100px;
      color: #4466ad;
      border: 1px solid #4466ad;
      .van-button__text {
        font-size: 12px;
      }
    }
    /deep/.idCard-span {
      .van-cell__title::after {
        display: inline-block;
        width: 18px;
        height: 18px;
        content: '';
        vertical-align: middle;
        background: url('../../assets/icon/invertedTriangle@2x.png') no-repeat 3px -1px;
        background-size: cover;
      }
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #cccccc;
      font-size: 16px;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #cccccc;
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #cccccc;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #cccccc;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #cccccc;
    }
    .bind-userinfo {
      text-align: left;
      padding-left: 16px;
      margin: 0px;
      // padding-top: 14px;
      // padding-bottom: 14px;
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #4466ad;
      padding-top: 20px;
      padding-bottom: 10px;
      display: inline-block;
    }
    .submit-button {
      border-radius: 5px;
      margin: 62px 16px 0px;
      margin-bottom: 100px;
      p {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        text-align: center;
        line-height: 17px;
        margin-top: 12px;
        em {
          font-style: normal;
          color: #5f97f4;
        }
      }
    }
    .van-button__text {
      font-family: PingFangSC-Regular;
      font-size: 18px;
      text-align: center;
    }
    .van-button {
      border-radius: 8px;
    }
  }
  .title {
    border-bottom: 1px solid #eeeeee;
    padding-top: 12px;
    padding-bottom: 12px;
    p {
      font-family: PingFangSC-Medium;
      font-size: 18px;
      color: #333333;
      text-align: center;
      line-height: 26px;
      margin: 0px;
    }
    span {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      color: #999999;
      text-align: center;
      line-height: 26px;
      display: block;
    }
  }
  .content {
    /deep/.van-cell__title {
      text-align: center;
    }
    /deep/.van-radio__label {
      width: 90%;
    }
    /deep/.van-radio__icon .van-icon {
      border: 0px solid transparent;
    }
    /deep/.van-radio__icon--checked .van-icon {
      background-color: transparent;
      border-color: transparent;
      color: #1989fa;
    }
    /deep/.van-radio {
      position: relative;
      margin: 0px 16px;
      border-bottom: 1px solid #eeeeee;
    }
    /deep/.van-radio__icon {
      position: absolute;
      right: 0px;
    }
    .van-cell {
      padding-left: 0px;
    }
  }
}
</style>
